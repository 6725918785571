.table-custom {
  border: solid 1px #cecece;

  thead {
    background-color: rgb(242, 242, 242);
  }

  > thead > tr > th {
    border-bottom: none;
  }
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding: 12px 8px;
}